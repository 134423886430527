
import React, { useEffect, useState } from 'react';
import { FaCalendar, FaCalendarCheck, FaCalendarDay, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <> <img className='computerOnly' src="/apoio/header-desktop.jpeg" style={{ width: '100%' }} />
        <img className='mobileOnly' src="/apoio/header-mobile2.jpeg" style={{ width: '100%' }} />
        <div style={{ width: '100%', background: '#F1F1F1', padding: '50px 20px' }}>
            <Container className='capa'>

                <Capa style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch' }}>


                    <div className='header column column-start headerLeft ' style={{ padding: '0px 50px', maxWidth: '400px' }} >

                        <div className='space-between full-width'>

                            <p className='color1 medium'>SESI CONECTA SAÚDE</p>
                            <p className='color1  medium'>2024</p>
                        </div>



                        <div className='column column-start full-width'>
                            <div className='space-between full-width'> <p className='color1 medium  p-title'>AS FRONTEIRAS</p><p className='color1 medium  p-title'>/ /</p> </div>
                            <p className='color1 medium p-title'>    DO CUIDADO ESTÃO </p>
                            <p className='color1 medium  p-title'>SE EXPANDINDO.</p>
                        </div>


                        <div className='full-width border1 gap-sm column column-start'>
                            <div className='space-between full-width'>

                                <p className='color1' style={{ fontSize: '16px' }}> <strong>21 E 22 DE NOVEMBRO</strong> DE 2024.</p>
                                <p className='color1'>/ /</p>
                            </div>
                            <div className='space-between full-width'>
                                <p className='color1' style={{ fontSize: '16px' }}> <strong>EVENTO GRATUITO</strong> </p><br />

                                <p className='color1'>/ /</p>
                            </div>


                        </div>

                    </div>

                    <div className='header column column-start' style={{ minWidth: 'auto', maxWidth: '380px', border: '1px solid #06496E', display: 'flex', background: '#fff', borderRadius: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px  50px' }}>
                        <div className='column column-start'>



                            <div className='column column-start gap-sm'>
                                <h2 className='color2' style={{ fontSize: '18px', marginBottom: '10px' }}>INSCREVA-SE</h2>

                                <p className='color2' style={{ width: 'auto', fontSize: '14px' }}>
                                    Garanta seu acesso a transmissão online ao vivo de todas as palestras
                                </p>
                                <a className='full-width' href="/app/sesi-conecta-2024/inscricao">  <button className='greenButtonBig  bold  full-width'>

                                    <strong>PARTICIPAÇÃO ONLINE</strong>
                                </button>
                                </a>
                                <a className='full-width' href="/app/sesi-conecta-2024/login">  <button className='greenButtonBig  bold  full-width'>

                                    <strong>ACESSAR TRANSMISSÃO</strong>
                                </button>
                                </a>
                                <p className='color2' style={{ width: 'auto', fontSize: '14px' }}>
                                    Confirme sua presença informando o código do convite
                                </p>
                                <a className='full-width' href="/presencial">  <button className='greenButtonBig bold full-width'>

                                    <strong> PARTICIPAÇÃO PRESENCIAL</strong>
                                </button>
                                </a>
                                {/*       <Link className='full-width' to="/app/sesi-conecta-2024/login">  <button className='greenButtonBig  bold  full-width'>

                                    <strong>ACESSAR TRANSMISSÃO</strong>
                                </button>
                                </Link>
 
                             <button className='greenButtonBig  bold  full-width'>

                                    <strong>INSCRIÇÕES SUSPENSAS</strong>
                                </button>
*/}
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexWrap: 'wrap' }}>
                                {configuration?.active_pages && configuration?.active_pages?.map(button => {
                                    return button?.button !== 'yes' ? <></> : button?.type === 'external' ? <a href={button.url} target="_BLANK" ><button className='greenButton2 shadow' style={{ background: theme?.buttonBackground, color: theme?.buttonColor }}>{button?.title}</button></a> : button?.type === 'page' ? <Link to={`/#${button.url}`} onClick={(e) => scrollTo(e, button?.url)} ><button className='greenButton2 shadow' style={{ background: theme?.buttonBackground, color: theme?.buttonColor }}>{button?.title}</button></Link> : <Link to={`/app/${projectHash}/${button.url}`} ><button className='greenButton2 shadow' style={{ background: theme?.buttonBackground, color: theme?.buttonColor }}>{button?.title}</button></Link>
                                })}


                            </div>
                        </div>
                    </div>




                </Capa>


            </Container >
        </div>
    </>
}

export default HomeApplication;