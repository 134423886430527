import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../../../../services/api';
import { date, hour, dateLanguage, simpleDate, hourEn } from '../../../../../../utils/date';
import { Container } from './styles';

import { useToast } from '../../../../../../hooks/Toast';


import { useLanguage } from '../../../../../../hooks/Language';

import Favorite from '../../../../../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { defaultProjectHash, hash, urlWebsite } from '../../../../../../config';
import { loadFromJson } from '../../../../../../utils/loadFromJson';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  theme?: Record<string, any>;
}

const ProgramByUnimed: React.FC<IProps> = ({ theme }) => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(0);

  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate, translateWithLanguage } = useLanguage();
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash } = useParams<Record<string, any>>();
  const [filter, setFilter] = useState({
    date: '',
    category: '',
    search: ''
  });

  const currentLanguage = configuration?.current_event_id_response?.theme?.language || 'ptBR';

  const addFilter = (column, value) => {

    const newFilter = { ...filter };
    newFilter[column] = value;

    setFilter(newFilter);

  }

  const newSearch = (data) => {

    const newFilter = { ...filter };
    newFilter.search = search;

    setFilter(newFilter);

  }

  useEffect(() => {

    let programsContent: Array<IProgramFilter> = [];

    if (filter.date) {
      programs.map(p => {
        if (p.simpleDate === filter.date) {
          programsContent.push({ ...p });
        }
      })
    }
    else {
      programsContent = [...programs];
    }



    if (filter.category !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programsContent.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.category?.toString()?.toLowerCase();
            const searchValue = filter?.category?.toString()?.toLowerCase();

            const indexText = text === searchValue;


            if (indexText) {

              filteredProgram[index].program.push(p1_program);
            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }



    if (filter.search !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programs.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.title?.toString()?.toLowerCase();
            const text2 = p1_program?.description?.toString()?.toLowerCase();
            const searchValue = filter?.search?.toString()?.toLowerCase();
            let indexText = text?.indexOf(searchValue);

            if (indexText < 0) {
              indexText = text2?.indexOf(searchValue);

            }


            if (indexText >= 0) {

              filteredProgram[index].program.push(p1_program);
            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }

    setReferenceProgram(programsContent);

  }, [programs, filter])

  const checkUser = async (id) => {

    try {
      const response = await api.post(`/check-activity/${id}`);

      if (response && response.data) {
        window.open(response?.data?.url, '_BLANK');
      }
      else {
        addToast({ type: "error", title: "Inscrição não encontrada" });
      }


    } catch (err) {
      addToast({ type: "error", title: "Inscrição não encontrada" });
    }
  }


  const groupList = {

    room_reference_id: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          room_name: rep.room_reference_id,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },

    category: (rep, program_ref: Array<Record<string, any>> = []) => {
      /* const index = program_ref.findIndex(
day => day?.formatted_date === rep.formatted_date && day?.category === rep.category,
); */
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );
      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          category: rep.category,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },


    default: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    }
  };

  const translateHourToLanguage = (date, lang) => {

    const languageOptions = {
      en: (dateValue) => hourEn(dateValue),

      ptBr: (dateValue) => hour(dateValue),
    }

    return languageOptions[lang] ? languageOptions[lang](date) : languageOptions['ptBr'](date);
  }

  const translateHourToLanguageSeparator = (lang) => {

    const languageOptions = {
      en: () => 'to',

      ptBr: () => 'às',
    }

    return languageOptions[lang] ? languageOptions[lang]() : languageOptions['ptBr']();
  }

  const load = async () => {
    let program_ref: Array<IProgramFilter> = [];
    const rooms = {};

    try {

      let data: Array<Record<string, any>> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Program' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(eventHash ? `/programs-list/${projectHash}/${eventHash}` : `/programs-list/${projectHash}`);

        data = response.data.rows;
      }



      if (data) {
        const responseData = data.reduce((prev, elem) => {
          elem.simpleDate = simpleDate(elem.date);
          elem.formatted_date = dateLanguage(elem.date, currentLanguage);
          elem.formatted_start_hour = translateHourToLanguage(elem.start_hour, currentLanguage);
          elem.formatted_end_hour = translateHourToLanguage(elem.end_hour, currentLanguage);

          if (elem?.[configuration?.theme_data?.program_list]) {
            rooms[elem?.[configuration?.theme_data?.program_list]] = "";
          }

          prev.push(elem);

          return prev;
        }, []);

        const itens: Array<IOption> = [];
        Object.keys(rooms).map(key => {
          itens.push({ label: key, value: key });
        });


        setRoomList(itens);

        if (responseData) {



          responseData.map(rep => {


            program_ref = configuration?.theme_data?.program_list && groupList[configuration?.theme_data?.program_list] ? groupList[configuration?.theme_data?.program_list](rep, program_ref) : groupList.default(rep, program_ref);





          });
        }

        program_ref.map((item, index) => {
          program_ref[index].program.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        program_ref.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });

        setPrograms(program_ref);
        setReferenceProgram(program_ref);
      }
    }
    catch (err) {

    }
  };

  useEffect(() => {


    load();
  }, []);

  const line = program => {
    return (
      <tr key={program?._id} style={{ cursor: 'pointer', borderBottom: '5px solid #fff' }} ><td  >

        <div className='row-to-column' style={{ gap: '10px', alignItems: 'center', width: '100%', justifyContent: 'flex-start' }}>
          <strong className='color2 medium toUpperCase ' style={{ width: '100%', maxWidth: '90px', fontSize: '21px' }}>
            {`
              ${program.formatted_start_hour}
              ${program.formatted_end_hour && `${translateHourToLanguageSeparator(currentLanguage)} ${program.formatted_end_hour}`}`}
          </strong>


          <strong style={{ width: '100%', display: 'inline-block', color: theme?.titleColor, fontSize: '16px', lineHeight: '19px' }}>
            {' '}

            {program.title}
            {' '}


          </strong>



          {program.category ? <><p className='color2 medium toUpperCase ' style={{ width: '100%', maxWidth: '150px', textAlign: 'center' }} > {program.category} </p><br /></> : <></>}
          <div className='row' style={{ alignItems: 'center', width: '100%', maxWidth: '150px' }}>
            {program?.images?.map(img => <img src={`${urlWebsite}/${img?.url}`} style={{ minWidth: '150px', width: '150px' }} />)}
          </div>
        </div>
      </td>   </tr>
    );
  };
  const { length } = programs;
  return (<div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', justifyContent: 'center', background: theme?.background || '#fff' }}>
    <Container id="programacao" className=' '  >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <p className='color1 medium  p-title'  >{translate(configuration?.program_title ? configuration?.program_title : 'Programação')} </p>

        <p className="color1 medium  p-title">/ /</p>
      </div>
      {configuration?.current_event_id_response?.theme?.program_head_description ? <p style={{ color: theme?.titleColor, textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.theme?.program_head_description }} /> : <></>}



      {referenceProgram.map(program => {
        return (program.program.length > 0 ? <div>
          <div className="programDayHeader" >


            {program.formatted_date && <p style={{ fontSize: '34px', width: '100%', textAlign: 'left' }} className="color2 medium toUpperCase "> {translate(program.formatted_date)}</p>}

          </div>
          <table>

            <tbody>{program.program.map(items => line(items))}</tbody>
          </table>

        </div> : <></>)
      })}


    </Container>

  </div>
  );
};
export default ProgramByUnimed;
