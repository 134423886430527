import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { CheckCourtesies } from './modules/listarCortesias';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';
import WhatsappCompartilhar from '../../../../components/WhatsAppCompartilhar';
import WhatsappCompartilharButton from '../../../../components/WhatsAppCompartilharButton';
import duplicar from './modules/duplicate';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

const SaleCortesy: React.FC = () => {
  const endpoint = '/v3/sales-cortesy';
  const title = 'Cortesias';

  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },

    hash: { ref: 'hash', column: 'hash', label: 'Código' },
    title: { ref: 'title', column: 'title', label: 'Identificação do cliente' },

    quantity: { ref: 'quantity', column: 'quantity', label: 'Quantidade de ingressos' },

    product_id: {
      ref: 'product_id',
      column: 'product_id_response',
      includeColumn: 'title',
      label: 'Produto / Ingresso',
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Identificação do cliente' },
    quantity: { model: 'input', type: 'text', name: 'quantity', label: 'Quantidade de ingressos' },


    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto / Ingresso',

      endpoint,
    },
    limit_date: { model: 'datePicker', type: 'text', name: 'limit_date', label: 'Prazo de validade' },

  };

  const formSchemaUpdate: Record<string, any> = {
    hash: { model: 'input', type: 'text', name: 'hash', label: 'Código da cortesia' },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () => <CheckCourtesies />
    ],
    bodyModules: [


      (lineData: Record<string, any>) => duplicar({ lineData }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        <CopyToClipboard text={`${urlWebsite}/app/${lineData?.project_id}/validar-cortesia/${lineData?.hash}`} />,
      (lineData: Record<string, any>) =>
        <WhatsappCompartilharButton text={`${urlWebsite}/app/${lineData?.project_id}/validar-cortesia/${lineData?.hash}`} />,
    ],
  };
  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SaleCortesy;
