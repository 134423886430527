import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiCopy, FiSend } from 'react-icons/fi';
import { uuid } from 'uuidv4';
import { useSearch } from '../../../../../../hooks/Search';
import { FaList, FaUps } from 'react-icons/fa';

interface ICreateModule {
  lineData: Record<string, any>;

}

export default function DuplicateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { reloadSearchAll } = useSearch();

  const handleSend = async (id: string): Promise<void> => {
    try {
      const response = await api.get(`/v3/sales-cortesy/${id}`);

      const hash = uuid()?.toUpperCase()?.split('-')?.[0];

      const data = {
        project_id: response?.data?.project_id,
        event_id: response?.data?.event_id,
        product_id: response?.data?.product_id,
        title: hash,
        quantity: response?.data?.quantity,
        limit_date: response?.data?.limit_date

      }
      await api.post(`/v3/sales-cortesy`, data);
      reloadSearchAll();
      addToast({ title: 'Duplicado!', type: 'success' });
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Duplicar" type="button" onClick={() => handleSend(id)}>
      <FaList />
    </button> : <></>
  );

  return moduleUpdate(lineData._id.toString());
}
