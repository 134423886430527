import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input2';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import Axios from 'axios';
import { useModal } from '../../hooks/Modal';
import { useLoading } from '../../hooks/LoadingHook';
import api from '../../services/api';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const Presencial: React.FC<IParams> = ({ redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const { addModal } = useModal();
  const { projectHash, eventHash } = useParams<IParamsURL>();

  const { configuration, setConfiguration } = useConfiguration();
  const { addLoading, removeLoading } = useLoading();


  const checkCourtesy = async (cortesyHash) => {

    addLoading({ title: 'Validando cortesia', key: 'cortesia' })

    try {
      const confirm = await api.get(`/v3/validate-cortesy-owner/${cortesyHash}/${configuration?.url}`);




      removeLoading('cortesia');
      return confirm?.data?.hash;
    }
    catch (err) {
      removeLoading('cortesia');
      return false;
    }


  }

  const handleSubmit = useCallback(
    async (data: Record<string, any>) => {
      setLoading(true);




      formRef.current?.setErrors({});

      if (!data?.code) {
        addModal({
          title: '', content: <>
            <h2 className='primaryColor'>Insira seu código do convite</h2>

          </>, theme: 'whiteModalMini'
        });
        setLoading(false);
        return;
      }

      const check = await checkCourtesy(data?.code);

      if (!check) {
        addModal({
          title: '', content: <>
            <h2 className='primaryColor'>Código não identificado</h2><br />
            <p style={{ textAlign: 'center' }}>Verifique seu código ou entre em contato com a organização.</p>
          </>, theme: 'whiteModalMini'
        })
        setLoading(false);
        return;
      }
      else {
        window.location.href = `/app/sesi-conecta-2024/validar-cortesia/${check}`
      }

      setLoading(false)

      // window.location.href = `/app/${configuration?.url}/dashboard`;
      //  window.location.href = redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');
      return;




    },
    [signIn, addToast, history],
  );

  return (<>
    {loading && <Loader message="Validando sua cortesia ..." />}

    <div className='fundoBlue full-width row' style={{ padding: '50px 20px', zIndex: 1000, boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)', }}>

      <div className='full-width header-sub' style={{ maxWidth: '1050px', justifyContent: 'space-between' }}>


        <div className='column full-width' style={{ maxWidth: '300px' }}>
          <p className='color-white medium midTitle'>PARTICIPAÇÃO</p>
          <div className='space-between gap-sm full-width'>


            <p className='color-white medium midTitle'>PRESENCIAL</p>
            <p className='color-white medium midTitle'>/ /</p>
          </div>

        </div>

        <div style={{ maxWidth: '250px', width: '100%' }}>
          <img src="/assets/logo3.png" className='full-width' />
        </div>

      </div>


    </div>
    <div className='full-width row-to-column gradient1' style={{ alignItems: 'flex-start', gap: '50px', paddingBottom: '100px' }}>
      <div className='column full-width' style={{ maxWidth: '400px', gap: '20px', padding: '50px 20px' }}>

        <p className='color-white' style={{ textAlign: 'left', marginBottom: '0px', fontSize: '16px' }}>
          <strong>Para seguir com sua inscrição</strong><br /> e participar do evento em formato<br /> presencial, informe o código descrito <br /> no convite que você recebeu.
        </p>


        <Form className="form" style={{ margin: '30px 0px' }} ref={formRef} onSubmit={handleSubmit}>


          <Input name="code" placeholder={translate('> DIGITE O CÓDIGO DO CONVITE')} />


          {errorMessage && errorMessage.title && <ErrorContainer>
            <h2>{errorMessage.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
          </ErrorContainer>}
          <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <button className='buttonBigGreen' style={{ margin: '0px auto', marginTop: '20px' }} type="submit">{translate('QUERO ME INSCREVER')}</button>
          </aside>



        </Form> {/*
        <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <button className='buttonBigGreen' style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('INSCRIÇÕES SUSPENSAS')}</button>
        </aside>*/}
        <p className='color-white' style={{ textAlign: 'left', marginBottom: '20px', fontSize: '16px' }}>

          A participação presencial no SESI Conecta Saúde é reservada aos convidados do SESI-RS.
          <strong> O código de participação está informado no convite do evento.</strong>
        </p>

        <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Whatsapp number='555192263846' message='DÚVIDAS' text='Olá, estou no Sesi Conecta Saúde' />
        </aside>
      </div>
      <div>
        <img className="full-width" src="/apoio/inscricao-presencial2.png" />
      </div>
    </div>

  </>
  );
};
export default Presencial;
