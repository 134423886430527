import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import Whatsapp from '../../components/Whatsapp';
import { FaWhatsapp } from 'react-icons/fa';

const FooterLayout: React.FC = ({ children }) => {
  return (<div style={{ zIndex: -1 }}>
    {/* <Particles
      params={{
        particles: {
          lineLinked: {
            enable: false,
            distance: 250
          },
          move: {
            speed: 15,
            direction: 'top-right',
            collisions: false,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },


          },
          opacity: {
            value: 0.4,
            random: false,
            animation: {
              enable: false
            }

          },
          shape: {
            type: 'circle',
            polygon: {

              nb_sides: 6,

            },

          },

          color: { value: 'rgb(171, 230, 255)' },

          number: {
            density: {
              enable: true,
              value_area: 2400,
            },
            value: 60,
          },
          size: {
            value: 4,
            random: true,
          },
        },
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
      }}
    /> */}


    <div className='full-width row' style={{ background: '#1C4D9D', padding: '50px 20px' }} >
      <div className="row-to-column full-width  " style={{ maxWidth: '1000px', alignItems: 'stretch', justifyContent: 'space-between', gap: '20px' }}>


        <div className='column column-start' style={{ maxWidth: '400px', gap: '20px' }}>
          <h2 className='midTitle color-white'> Quer falar conosco? </h2>
          <p className='color-white'>Para dúvidas e mais informações, fale com a gente pelo telefone <strong>0800 051 8555</strong>.</p>

          <div className='row-start' style={{ display: 'flex', alignItems: 'center' }}>
            <a href="https://www.instagram.com/sesirsoficial/" target='_BLANK'>
              <img src="/apoio/instagram.png" style={{ width: '48px' }} />
            </a>

            <a href="https://www.linkedin.com/company/sesirsoficial/" target='_BLANK'>
              <img src="/apoio/linkedin.png" style={{ width: '48px' }} />
            </a>

            <a href={`https://api.whatsapp.com/send?l=pt&phone=555192263846&text=${encodeURI(
              'Olá, estou no Sesi Conecta Saúde'
            )}`} target='_BLANK'>
              <FaWhatsapp style={{ fontSize: '40px', color: '#fff' }} />
            </a>
          </div>

        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '10px' }}>
          <img src="/apoio/assinaturas.png" style={{ width: '100%', maxWidth: '300px', alignSelf: 'flex-end' }} />
        </div>


      </div>

    </div>
  </div>
  );
};

export default FooterLayout;
